<template>
  <div>
    <b-row>
      <b-col
        cols="6"
        class="mb-1"
      >
        <div class="title">
          Paciente
        </div>
        <div
          v-if="patient"
          class="subtitle"
        >
          {{ patient.patient.first_name + ' ' + patient.patient.last_name }}
        </div>
      </b-col>
      <b-col
        cols="6"
        class="mb-1"
      >
        <div class="title">
          Cama
        </div>
        <div
          v-if="patient"
          class="subtitle"
        >
          {{ patient.name }}
        </div>
      </b-col>
    </b-row>
    <base-actions-table
      :columns="fields"
      :records="records"
      :busy="isBusy"
      :module="module"
      @creating="handleCreating"
      @editing="handleEditing"
      @deleting="handleDeleting"
    >
      <template #cell(state)="data">
        {{ data.item.is_made ? 'Realizado' : 'Sin realizar' }}
      </template>
    </base-actions-table>
    <b-modal
      id="modal-program-exam"
      centered
      title="Programar exámen"
      size="sm"
      hide-footer
    >
      <assign-exam-form
        :patient="patient"
        :record="record"
        @save="save"
      />
    </b-modal>
    <b-modal
      id="modal-delete-exam"
      centered
      title="Eliminar examén"
      size="sm"
      hide-footer
    >
      <delete-exam-form
        :is-busy="isBusy2"
        @delete="deleteExam"
      />
    </b-modal>
  </div>
</template>

<script>
import { BRow, BCol, BModal } from 'bootstrap-vue'
import AssignExamForm from './AssignExamForm'
import DeleteExamForm from './DeleteExamForm'
import PermissionMixin from '@/mixins/PermissionMixin'
import BaseActionsTable from '@/components/base/BaseActionsTable'

export default {
	inject: ['assignExamPatientRepository'],
	components: {
		BRow,
		BCol,
		BModal,
		AssignExamForm,
		DeleteExamForm,
		BaseActionsTable,
	},
	mixins: [PermissionMixin],
	props: {
		patient: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {
			records: [],
			fields: [
				{
					key: 'exam.name',
					label: 'Examén',
				},
				{
					key: 'programmed_date',
					label: 'Programado',
				},
				{
					key: 'realized',
					label: 'Realizado',
				},
				{
					key: 'care_staff',
					label: 'Personal asistencial',
				},
				{
					key: 'state',
					label: 'Estado',
				},
				{
					key: 'cancel_observation',
					label: 'Observación de cancelar',
				},
				{
					key: 'actions',
					label: 'Acciones',
				},
			],
			isBusy: false,
			isBusy2: false,
			module: 'patientExam',
			record: null,
		}
	},
	mounted() {
		const me = this
		me.loadExamPatient(me.patient.patient.id)
	},
	methods: {
		async loadExamPatient(patientId) {
			const me = this
      me.records = []
			try {
				me.isBusy = true
				const { data } = await me.assignExamPatientRepository.getAll(patientId)
				data.forEach(el => {
					if (!el.is_made) me.records.push(el)
				})
			} catch (error) {
				// console.log(error)
			} finally {
				me.isBusy = false
			}
		},
		save() {
			const me = this
			me.$bvModal.hide('modal-program-exam')
			me.loadExamPatient(me.patient.patient.id)
		},
		handleCreating() {
			const me = this
			me.record = null
			me.$bvModal.show('modal-program-exam')
		},
		handleEditing(record) {
			const me = this
			const data = {
				id: record.id,
				exam_id: record.exam_id,
				programmed_date: record.programmed_date,
			}
			me.record = data
			me.$bvModal.show('modal-program-exam')
		},
		async handleDeleting(record) {
			const me = this
			me.record = record
			me.$bvModal.show('modal-delete-exam')
		},
		async deleteExam(formData) {
			const me = this
			me.$bvModal
				.msgBoxConfirm('¿Está seguro que desea eliminar el registro?', {
					title: 'Eliminar registro',
					size: 'sm',
					okVariant: 'danger',
					okTitle: 'Eliminar',
					buttonSize: 'sm',
					hideHeaderClose: false,
					cancelTitle: 'Cancelar',
					cancelVariant: 'outline-secondary',
					centered: true,
					modalClass: 'modal-danger',
				})
				.then(async value => {
					if (value) {
						me.isBusy2 = true
						try {
							await me.assignExamPatientRepository.delete(me.record.id, me.patient.patient.id, formData)
							me.loadExamPatient(me.patient.patient.id)
							me.record = null
							me.$bvModal.hide('modal-delete-exam')
						} catch (e) {
							console.error(e)
							me.$bvToast.toast('Error', {
								title: 'Ocurrió un error al eliminar el registro',
								toaster: 'b-toaster-bottom-right',
								variant: 'danger',
								solid: true,
								appendToast: true,
							})
						} finally {
							me.isBusy2 = false
						}
					}
				})
		},
	},
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.title {
	font-size: 16px;
	color: #636363;
	font-weight: 800;
	margin-bottom: 5px;
}

.subtitle {
	font-size: 14px;
	color: #636363;
}
</style>
