<template>
  <div class="">
    <b-row>
      <b-col
        v-for="(item, index) in patients"
        :key="index"
        cols="4"
      >
        <b-card
          no-body
          class="card_"
        >
          <template #header>
            <b-col
              md="4"
              class="d-flex align-items-center pl-25"
            >
              <div class="">
                <div class="title">
                  Riesgos
                </div>
                <div v-if="item.location_patient">
                  <div
                    v-for="risk in item.location_patient.risks.slice(0, 3)"
                    :key="risk.id"
                    v-b-tooltip.hover.top="risk.name"
                    class="bar_rish"
                    :style="'background-color: ' + risk.color"
                  />
                </div>
              </div>
              <b-button
                v-if="item.patient"
                v-b-tooltip.hover.top="'Tiempo ulcera'"
                size="sm"
                variant="secondary"
                class="btn-icon ml-25"
              >
                <feather-icon icon="ClockIcon" />
              </b-button>
            </b-col>
            <b-col
              md="5"
              class="d-flex align-items-center justify-content-center"
            >
              <div class="title3">
                {{ item.name }}
              </div>
            </b-col>
            <b-col
              md="3"
              class="d-flex align-items-center"
            >
              <b-button
                v-b-tooltip.hover.top="'Temperatura'"
                size="sm"
                variant="secondary"
                class="btn-icon"
              >
                {{ item.device ? item.device : '-' }}°C
              </b-button>
            </b-col>
          </template>
          <div class="padding_">
            <b-row class="mb-1">
              <b-col
                v-if="item.patient"
                cols="6"
              >
                <div class="title2">
                  Paciente
                </div>
                <div class="subtitle">
                  {{ item.patient.first_name + ' ' + item.patient.last_name }}
                </div>
              </b-col>
              <b-col
                v-if="item.patient"
                cols="6"
              >
                <div class="title2">
                  Identificación
                </div>
                <div class="subtitle">
                  {{ item.patient.document_number }}
                </div>
              </b-col>
              <b-col
                v-if="item.eps"
                cols="6"
              >
                <div class="title2">
                  EPS
                </div>
                <div class="subtitle">
                  {{ item.eps.long_name }}
                </div>
              </b-col>
              <b-col
                v-if="item.patient"
                cols="6"
              >
                <div class="title2">
                  Exámenes
                </div>
                <div
                  class=""
                  @click="modalExamen(item)"
                >
                  <div class="text-center">
                    <b-progress
                      :max="100"
                      animated
                      variant="info"
                    >
                      <b-progress-bar
                        :value="
                          (item.exams.length /
                            item.examsTotal.length) *
                            100
                        "
                      >
                        <strong>{{
                          (item.exams.length /
                            item.examsTotal.length) *
                            100
                        }}</strong>
                      </b-progress-bar>
                    </b-progress>
                    <span
                      class=""
                    ><strong>{{ item.exams.length }} /
                      {{ item.examsTotal.length }}</strong></span>
                  </div>
                </div>
              </b-col>
              <!-- <b-col
                v-if="item.patient"
                cols="6"
              >
                <div class="title2">
                  Medicamentos
                </div>
                <div
                  class="d-flex align-items-center"
                  @click.prevent="asignarMedicamentos(data.item)"
                >
                  <div class="container-indicative-progress">
                    <div
                      style="width: 66%"
                      class="container-progress"
                    >
                      <span class="value-progress">66%</span>
                      <div class="progresss" />
                    </div>
                    <span class="indicative">2/3</span>
                  </div>
                </div>
              </b-col> -->
              <b-col
                v-if="item.patient"
                cols="6"
              >
                <div class="title2">
                  Dietas
                </div>
                <div class="d-flex align-items-center min">
                  <div
                    v-if="item.patient"
                    class="d-flex align-items-center min"
                  >
                    <div
                      v-if="isBusy"
                      class="text-center text-primary"
                    >
                      <b-spinner class="align-middle" />
                    </div>
                    <div
                      v-else-if="isError"
                      class="btn_reload"
                      @click.prevent="$emit('reloadDiets')"
                    >
                      <b-icon-arrow-clockwise />
                    </div>
                    <div
                      v-else-if="isEmpty"
                      style="cursor: pointer"
                      @click.prevent="asignarDiet()"
                    >
                      <b-badge variant="danger">
                        Asignar
                      </b-badge>
                    </div>
                    <div
                      v-else
                      class="container-indicative-progress"
                      style="cursor: pointer"
                      @click.prevent="asignarDiet()"
                    >
                      <div
                        v-if="item.orders.length > 0"
                        class="d-flex"
                      >
                        <div
                          v-for="(option, i) in item.orders"
                          :key="i"
                          v-b-tooltip.hover.top="textDietTooltip(option.type)"
                          class="box_food"
                          :class="colorDiet(option.type, option.status)"
                        >
                          <div>
                            {{ textDiet(option.type) }}
                          </div>
                        </div>
                      </div>
                      <div v-else>
                        <b-badge variant="danger">
                          Asignar
                        </b-badge>
                      </div>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <div class="d-flex align-items-center justify-content-center">
                  <b-button
                    v-if="isMaintenance1(item)"
                    v-b-tooltip.hover.top="'Ingresar paciente'"
                    size="sm"
                    variant="primary"
                    class="btn-icon mr-25"
                    @click="goFindPatient(item)"
                  >
                    <feather-icon icon="PlusCircleIcon" />
                  </b-button>
                  <template v-else-if="isMaintenance2(item)">
                    <b-button

                      v-b-tooltip.hover.top="'Dar de alta'"
                      size="sm"
                      variant="warning"
                      class="btn-icon mr-25"
                      @click="altaInfo(item)"
                    >
                      <feather-icon icon="LogOutIcon" />
                    </b-button>
                    <b-button
                      v-b-tooltip.hover.top="'Historial paciente'"
                      size="sm"
                      variant="secondary"
                      class="btn-icon mr-25"
                      @click="redirect('patientHistory')"
                    >
                      <feather-icon icon="EyeIcon" />
                    </b-button>
                    <b-button
                      v-b-tooltip.hover.top="'Trasladar paciente'"
                      size="sm"
                      variant="info"
                      class="btn-icon mr-25"
                      @click="transferPatient(item)"
                    >
                      <feather-icon icon="RepeatIcon" />
                    </b-button>
                    <b-button
                      v-b-modal.modal-failure-attention
                      v-b-tooltip.hover.top="'Falla atención'"
                      size="sm"
                      variant="success"
                      class="btn-icon mr-25"
                    >
                      <feather-icon icon="AlertTriangleIcon" />
                    </b-button>
                  </template>

                  <b-button
                    v-else
                    v-b-tooltip.hover.top="'Fuera de servicio'"
                    size="sm"
                    variant="danger"
                    class="btn-icon mr-25"
                    @click.prevent="modalMaintenance(item)"
                  >
                    <feather-icon icon="AlertCircleIcon" />
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
	BCard,
	BRow,
	BCol,
	VBModal,
	BButton,
	VBTooltip,
	BBadge,
	BProgress,
	BProgressBar,
  BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
	components: {
		BCard,
		BRow,
		BCol,
		BButton,
		BBadge,
    BProgress,
		BProgressBar,
    BSpinner,
	},
	directives: {
		'b-modal': VBModal,
		'b-tooltip': VBTooltip,
		Ripple,
	},
	props: {
		patients: {
			type: Array,
			default: null,
		},
		isBusy: {
			type: Boolean,
			default: false,
		},
		isError: {
			type: Boolean,
			default: false,
		},
		isEmpty: {
			type: Boolean,
			default: false,
		},
	},
	methods: {
		goFindPatient(item) {
			this.$emit('modalFindPatient', item)
			// this.$router.push({ name: 'find-paciente', params: { locationId: id } })
		},
		asignarMedicamentos(item) {
			// this.$router.push({ name: 'assignMedicines' })
			this.$emit('assignMedicines', item)
		},
		asignarDiet() {
			this.$router.push({ name: 'nurse' })
		},
		redirect(name, id) {
			this.$router.push({ name: name, params: { patientUuid: id } })
		},
		modalExamen(item) {
			this.$emit('modalExamen', item)
		},
		transferPatient(item) {
			this.$emit('transferPatient', item.location_patient.id, item.area_id)
		},
		altaInfo(item) {
			this.$bvModal
				.msgBoxConfirm(
					'¿Está seguro que desea dar de alta informativo a este paciente?, esta acción informará al personal encargado del proceso de salida del paciente.',
					{
						title: 'De alta informativo',
						size: 'sm',
						okVariant: 'warning',
						okTitle: 'Alta info',
						buttonSize: 'sm',
						hideHeaderClose: false,
						cancelTitle: 'Cancelar',
						cancelVariant: 'outline-secondary',
						centered: true,
						modalClass: 'modal-warning',
					},
				)
				.then(async value => {
					if (value) {
						this.$emit('altaInfo', item.location_patient.id, item.area_id)
					}
				})
		},
		textDiet(type) {
			let text = ''

			if (type == '1') {
				text = 'D'
			} else if (type == '2') {
				text = 'A'
			} else {
				text = 'C'
			}
			return text
		},
		colorDiet(type, status) {
			let color = ''

			if (type == '1' && status) {
				color = 'breakfast'
			} else if (type == '2' && status) {
				color = 'luch'
			} else if (type == '3' && status) {
				color = 'dinner'
			}
			return color
		},
		textDietTooltip(type) {
			let text = ''

			if (type == '1') {
				text = 'Desayuno'
			} else if (type == '2') {
				text = 'Almuerzo'
			} else {
				text = 'Cena'
			}
			return text
		},
    isMaintenance1(item) {
      let isVisible = false

      if (item.patient) {
        isVisible = false
      } else if (item.bed_request && item.bed_request.length > 0) {
        const bedActive = item.bed_request.find(el => el.finished_date == null)
        if (bedActive) isVisible = false
      } else if (item.maintenance_service && item.maintenance_service.length > 0) {
        const maintenanceActive = item.maintenance_service.find(el => el.status == '1')
        if (maintenanceActive) isVisible = false
      } else {
        isVisible = true
      }

      return isVisible
    },
    isMaintenance2(item) {
      let isVisible = false

      if (item.patient) {
        isVisible = true
      } else if (item.bed_request && item.bed_request.length > 0) {
        const bedActive = item.bed_request.find(el => el.finished_date == null)
        if (bedActive) isVisible = false
      } else if (item.maintenance_service && item.maintenance_service.length > 0) {
        const maintenanceActive = item.maintenance_service.find(el => el.state == '1')
        if (maintenanceActive) isVisible = false
      }

      return isVisible
    },
    modalMaintenance(item) {
      const me = this
      let value = null
      if (item.bed_request && item.bed_request.length > 0) {
        value = item.bed_request.find(el => el.finished_date == null)
      } else {
        value = item.maintenance_service.find(el => el.state == '1')
      }

      me.$emit('modalMaintenance', { observations: value.observations })
    },
	},
}
</script>

<style lang="scss" scoped>
.card_ {
	border: 1px solid #6e6b7b;
}

.card-header[data-v-f94a8f28] {
	background-color: rgb(236, 234, 234);
	padding: 4px;
	height: 59px;
}

.padding_ {
	padding: 8px;
}

.title {
	font-size: 11px;
	color: #636363;
}

.title2 {
	font-size: 12px;
	color: #636363;
	font-weight: 700;
}

.title3 {
	font-size: 16px;
	color: #636363;
	font-weight: 700;
}

.subtitle {
	font-size: 13px;
	color: #636363;
}

.bar_rish {
	padding: 4px;
	border-radius: 50px;
	background-color: #7367f0;
	margin-bottom: 2px;
	width: 40px;
}

.container-indicative-progress {
	display: flex;
	flex-direction: column;
	flex: 1;
	align-items: center;
	cursor: pointer;
}
.container-progress {
	height: 1rem;
	width: 100%;
	background-color: #f5f5f5;
	position: relative;
	overflow: hidden;
	border-radius: 0.4rem;
	.value-progress {
		position: absolute;
		text-align: center;
		right: 0rem;
		left: 0rem;
		font-size: 0.7rem;
		top: 0rem;
		font-weight: bold;
	}
	.progresss {
		background-color: #30cfe8;
		height: 100%;
		width: 33%;
	}
}
.dieta {
	background-color: #7367f0;
}
.indicative {
	margin-top: 0.2rem;
}

.btn_reload {
	color: #453b7a !important;
	cursor: pointer;
	font-size: 25px;
}

.box_food {
	background: #b2b2b2;
	width: 100%;
	height: 30px;
	margin-right: 5px;
	font-size: 16px;
	color: black;
	border-radius: 0.2rem;
	text-align: center;
	display: flex;
	align-items: center;
	padding: 8px;
	font-weight: 700;
}

.box_food.breakfast {
	background-color: #28c76f;
	color: white;
}

.box_food.luch {
	background-color: #7367f0;
	color: white;
}

.box_food.dinner {
	background-color: #ea5455;
	color: white;
}
</style>
