<template>
  <div>
    <b-row class="p-1">
      <b-col
        cols="3"
      >
        <div class="title">
          Paciente
        </div>
        <div
          v-if="patient"
          class="subtitle"
        >
          {{ patient.patient.first_name + ' ' + patient.patient.last_name }}
        </div>
      </b-col>
      <b-col
        cols="3"
      >
        <div class="title">
          Fecha de ingreso
        </div>
        <div class="subtitle">
          27/10/2021
        </div>
      </b-col>
      <b-col
        cols="3"
      >
        <div class="title">
          Cama
        </div>
        <div
          v-if="patient"
          class="subtitle"
        >
          {{ patient.name }}
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <div class="title">
          Listado de medicamentos asignados
        </div>
      </b-col>
      <b-col
        cols="12"
        class="d-flex"
      >
        <b-button
          variant="success"
          class="mr-2"
          size="sm"
          @click="assign"
        >
          <feather-icon icon="PlusIcon" />
          Asignar
        </b-button>
        <b-button
          variant="warning"
          size="sm"
        >
          <feather-icon icon="AlertTriangleIcon" />
          Falla atención
        </b-button>
      </b-col>
      <b-col cols="12">
        <b-row>
          <b-col
            md="2"
            sm="4"
            class="my-1"
          >
            <b-form-group
              class="mb-0"
            >
              <label class="d-inline-block text-sm-left mr-50">Por página</label>
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                :options="pageOptions"
                class="w-50"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            class="my-1"
          >
            <b-form-group
              label="Filter"
              label-cols-sm="3"
              label-align-sm="right"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!filter"
                    @click="filter = ''"
                  >
                    Limpiar
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-table
              responsive
              class="text-center"
              small
              :per-page="perPage"
              :current-page="currentPage"
              :items="items"
              :fields="fields"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
            >
              <!-- medicines_alert -->
              <template #cell(medicines_alert)="data">
                <div class="d-flex align-items-center justify-content-around">
                  <div class="">
                    {{ data.item.medicine }}
                  </div>
                  <b-button
                    v-b-tooltip.hover.top="'Observación'"
                    size="sm"
                    variant="secondary"
                    class="btn-icon"
                    @click="modalObservation(data.item.observation)"
                  >
                    <feather-icon icon="MessageCircleIcon" />
                  </b-button>
                </div>
              </template>

              <!-- medications_received -->
              <template #cell(medications_received)="">
                <div class="d-flex align-items-center justify-content-center">
                  <b-button
                    v-b-tooltip.hover.top="'Aceptar'"
                    size="sm"
                    variant="primary"
                    class="btn-icon margin-rigth"
                  >
                    <feather-icon icon="CheckIcon" />
                  </b-button>
                  <b-button
                    v-b-tooltip.hover.top="'Rechazar'"
                    size="sm"
                    variant="danger"
                    class="btn-icon margin-rigth"
                  >
                    <feather-icon icon="XIcon" />
                  </b-button>
                </div>
              </template>

              <!-- state -->
              <template #cell(state)="data">
                <template v-if="data.item.state == 'Aceptado'">
                  <div
                    class="status_bar accepted"
                  />
                  <div class="">
                    {{ data.item.state }}
                  </div>
                </template>
                <template v-else>
                  <div
                    class="status_bar rejected"
                  />
                  <div class="">
                    {{ data.item.state }}
                  </div>
                </template>
              </template>
            </b-table>
          </b-col>

          <b-col
            cols="12"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="center"
              size="sm"
              class="my-0"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <detail-observation-modal :observation="observation" />
    <b-modal
      id="assign-medicine"
      centered
      title="Asignar medicamentos"
      size="lg"
      hide-footer
    >
      <form-assign-medicine :patient="patient" />
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  VBModal,
  BTable,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import DetailObservationModal from '../assign-medicines/DetailObservationModal'
import FormAssignMedicine from '../assign-medicines/FormAssignMedicine'

export default {
components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BFormSelect,
    BPagination,
    BInputGroup,
    BInputGroupAppend,
    BTable,
    DetailObservationModal,
    FormAssignMedicine,
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
		patient: {
			type: Object,
			default: null,
		},
	},
  data() {
    return {
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      filter: null,
      filterOn: [],
      fields: [
        {
          key: 'medicines_alert', label: 'Medicamentos/Alert',
        },
        {
          key: 'amount', label: 'Cantidad',
        },
        {
          key: 'medications_received', label: 'Medicamentos recibidos',
        },
        {
          key: 'returned', label: 'Devueltos',
        },
        {
          key: 'programmeed', label: 'Programado',
        },
        {
          key: 'realized', label: 'Realizado',
        },
        {
          key: 'care_staff', label: 'Personal asistencial',
        },
        {
          key: 'state', label: 'Estado',
        },
      ],
      items: [
        {
          medicine: 'Ibuprofeno 500mg',
          alert: '',
          observation: 'Lorem lorem lorem',
          amount: '10',
          returned: '1',
          programmeed: '24/10/2021',
          realized: '24/10/2021',
          care_staff: 'Lionel Messi',
          state: 'Rechazado',
        },
        {
          medicine: 'Acetaminofen',
          alert: '',
          observation: 'Lorem lorem lorem',
          amount: '5',
          returned: '1',
          programmeed: '24/10/2021',
          realized: '24/10/2021',
          care_staff: 'Lionel Messi',
          state: 'Aceptado',
        },
      ],
      observation: '',
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    modalObservation(observation) {
      const me = this
      me.observation = observation
      me.$bvModal.show('modal-detailt-observation')
    },
    assign() {
      this.$bvModal.show('assign-medicine')
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
  .card__ {
    box-shadow: 0 5px 11px 0 rgba(145, 144, 144, 0.3), 0 4px 15px 0 rgba(133, 132, 132, 0.15);
  }

  .title {
    font-size: 16px;
    color: #636363;
    font-weight: 800;
    margin-bottom: 5px;
  }

  .margin-rigth {
    margin-right: 0.5rem;
  }

  .subtitle {
    font-size: 14px;
    color: #636363;
  }

  .status_bar {
    padding: 4px 10px;
    border-radius: 50px;
  }

  .accepted {
    background-color: #7367F0;
  }

  .rejected {
    background-color: #EA5455;
  }

</style>
