<template>
  <div>
    <b-form>
      <b-row>
        <b-col md="12">
          <b-form-group
            label="Observación"
            label-for="observations"
          >
            <b-form-textarea
              id="observations"
              v-model="record.observations"
              disabled
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
	BForm,
	BFormGroup,
	BCol,
	BRow,
	BFormTextarea,
} from 'bootstrap-vue'

export default {
	components: {
		BForm,
		BFormGroup,
		BCol,
		BRow,
		BFormTextarea,
	},
	props: {
		record: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {}
	},
}
</script>
