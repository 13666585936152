<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-form-group
          label="Observación"
          label-for="cancel_observation"
        >
          <b-form-textarea
            id="cancel_observation"
            v-model="formData.cancel_observation"
            placeholder="Observación"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6" />
      <b-col
        md="6"
        class="text-right"
      >
        <b-button
          type="button"
          variant="danger"
          :disabled="isBusy || $v.$invalid"
          @click="saveData"
        >
          <b-spinner
            v-if="isBusy"
            small
          />
          <feather-icon
            v-else
            icon="Trash2Icon"
          />
          Eliminar
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
	BFormGroup,
	BButton,
	BCol,
	BRow,
	BSpinner,
	BFormTextarea,
} from 'bootstrap-vue'
import { v4 as uuidv4 } from 'uuid'
import { required } from 'vuelidate/lib/validators'

export default {
  components: {
		BFormGroup,
		BButton,
		BCol,
		BRow,
		BSpinner,
		BFormTextarea,
	},
  props: {
    isBusy: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formData: {
        cancel_observation: null,
      },
    }
  },
  validations: {
    formData: {
      cancel_observation: {
        required,
      },
    },
  },
  methods: {
    saveData() {
      const me = this
      me.$emit('delete', me.formData)
    },
  },
}
</script>

<style>

</style>
