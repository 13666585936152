<template>
  <b-row>
    <b-col cols="12">
      <b-form-group
        label="Examén*"
        label-for="exam_id"
      >
        <v-select
          v-model="formData.exam_id"
          label="name"
          :options="typeExams"
          :reduce="(option) => option.id"
          placeholder="Seleccione examén"
        >
          <div slot="no-options">
            No hay opciones
          </div>
        </v-select>
      </b-form-group>
    </b-col>
    <b-col cols="6">
      <date-picker
        v-model="formData.programmed_date"
        :attributes="attrs"
        mode="date"
        :model-config="modelConfig"
        :available-dates="{ start: new Date(), end: null }"
      >
        <template v-slot="{ inputValue, inputEvents }">
          <b-form-group
            label="Fecha programa*"
            label-for="date"
          >
            <b-form-input
              :value="inputValue"
              v-on="inputEvents"
            />
          </b-form-group>
        </template>
      </date-picker>
    </b-col>
    <b-col
      cols="6"
      class="mb-1"
    >
      <b-form-group
        label="Hora programa*"
        label-for="programmed_time"
      >
        <b-form-input
          v-model="formData.programmed_time"
          label="programmed_time"
          type="time"
          step="2"
        />
      </b-form-group>
    </b-col>
    <b-col
      cols="12"
      class="mb-1 mt-2 text-center"
    >
      <b-button
        variant="success"
        :disabled="isBusy || $v.$invalid"
        @click.prevent="saveData"
      >
        <b-spinner
          v-if="isBusy"
          small
        />
        <v-icon
          v-else
          name="save"
        />
        Guardar
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import {
	BButton,
	BRow,
	BCol,
	BFormGroup,
	BFormInput,
	BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import { required } from 'vuelidate/lib/validators'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'

moment.locale('es')

export default {
	inject: ['examsRepository', 'assignExamPatientRepository'],
	components: {
		BButton,
		BRow,
		BCol,
		BFormGroup,
		BFormInput,
		vSelect,
		DatePicker,
		BSpinner,
	},
	props: {
		patient: {
			type: Object,
			default: null,
		},
		record: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {
			typeExams: [],
			formData: {
				exam_id: null,
				programmed_date: null,
				programmed_time: moment().format('HH:mm:ss'),
			},
			modelConfig: {
				type: 'string',
				mask: 'YYYY-MM-DD',
			},
			attrs: [
				{
					highlight: 'blue',
					dates: new Date(),
				},
			],
			isBusy: false,
		}
	},
	validations: {
		formData: {
			exam_id: {
				required,
			},
			programmed_date: {
				required,
			},
			programmed_time: {
				required,
			},
		},
	},
	mounted() {
		const me = this
		me.loadTypeExams()
		if (me.record) {
			me.formData = { ...me.formData, ...me.record }
		}
	},
	methods: {
		async loadTypeExams() {
			const me = this
			try {
				const { data } = await me.examsRepository.getAll()
				data.forEach(el => {
					me.typeExams.push({
						id: el.id,
						name: el.name,
					})
				})
			} catch (error) {
				// console.log(error)
			}
		},
		async saveData() {
			const me = this
			try {
				me.isBusy = true
				const id = me.formData.id ? me.formData.id : uuidv4()
        const option = me.formData.id ? 'update' : 'save'

				await me.assignExamPatientRepository[option](id, {
					...me.formData,
					patient_id: me.patient.patient.id,
				})
				me.$emit('save')
			} catch (error) {
				// console.log(error)
			} finally {
				me.isBusy = false
			}
		},
	},
}
</script>

<style></style>
