import useJwt from '@core/auth/jwt/useJwt'
import axios from '@axios'
import { Subject } from 'rxjs'

import { generateUUID } from '@core/utils/utils'

const { jwt } = useJwt(axios, {})

// Objetos Observable y Emiters
const showTransferPatientModal$ = new Subject()

function emmiterAddMessagge(val) {
	showTransferPatientModal$.next(val)
}

function getSubjetAddMessagge() {
	return showTransferPatientModal$
}

// Methods HTTP

function searchPatient(document) {
	const params = {
		'filters[0][field]': 'document',
		'filters[0][operator]': '=',
		'filters[0][value]': document,
		order_by: 'first_name',
		order: 'asc',
		limit: '1',
		offset: '0',
	}
	return jwt.get('/patients', params)
}

function registerPatient(data) {
	return jwt.put(`/patients/${generateUUID()}`, data)
}

export {
	// Observables, emmiters
	emmiterAddMessagge,
	getSubjetAddMessagge,
	showTransferPatientModal$,
	// HTTP
	searchPatient,
	registerPatient,
}
