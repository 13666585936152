<template>
  <b-modal
    id="modal-detailt-observation"
    centered
    title="Observación"
  >
    <b-row>
      <b-col cols="12">
        <div class="">
          {{ observation }}
        </div>
      </b-col>
    </b-row>
    <template #modal-footer="{ close }">
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        v-b-modal.modal-seconday
        variant="outline-secondary"
        @click="close()"
      >
        Cerrar
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  BModal,
  VBModal,
  BButton,
  BRow,
  BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BModal,
    BButton,
    BRow,
    BCol,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    observation: {
      type: String,
      default: null,
    },
  },
  data() {
    return {}
  },
}
</script>

<style lang="scss"></style>
