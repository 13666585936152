<template>
  <b-col cols="4">
    <b-card
      no-body
      class="card__"
    >
      <b-card-header class="p-1">
        DIETAS
      </b-card-header>
      <b-row>
        <b-col
          v-for="(item, index) in statisticDiet"
          :key="index"
          cols="12"
        >
          <h5 class="ml-2 mb-0">
            {{ item.type }}: {{ item.total }}
          </h5>
          <div class="padding__">
            <b-progress
              :key="item.variant"
              :max="100"
              animated
              :variant="item.variant"
            >
              <b-progress-bar :value="item.value">
                <strong>{{ item.value }}</strong>
              </b-progress-bar>
            </b-progress>
            <span
              class=""
            ><strong>{{ item.delivered }} / {{ item.total }}</strong></span>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </b-col>
</template>

<script>
import {
	BCard,
	BRow,
	BCol,
	BCardHeader,
	BProgress,
	BProgressBar,
} from 'bootstrap-vue'

export default {
  components: {
		BCard,
		BRow,
		BCol,
		BCardHeader,
		BProgress,
		BProgressBar,
	},
  props: {
    statisticDiet: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<style>

</style>
