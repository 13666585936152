<template>
  <div>
    <b-row class="mb-2">
      <b-col
        lg="3"
        md="3"
        sm="12"
      >
        <b-form-group
          label="Cama"
          label-for="bed"
        >
          <b-form-input
            id="bed"
            v-model="location.name"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col
        lg="5"
        md="5"
        sm="12"
      >
        <b-form-group
          label="Tipo de documento*"
          label-for="document_type"
        >
          <v-select
            v-model="document_type_id"
            label="text"
            placeholder="Seleccione"
            :options="documentTypes"
            :reduce="(option) => option.value"
          />
        </b-form-group>
      </b-col>
      <b-col
        lg="4"
        md="4"
        sm="12"
      >
        <b-form-group
          label="Número de documento"
          label-for="document_number"
        >
          <b-form-input
            id="document_number"
            v-model="document_number"
            placeholder="Número de documento"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        class="text-center mt-1"
      >
        <b-button
          type="button"
          variant="primary"
          :disabled="isBusy || $v.$invalid"
          @click="handleSearchPatient"
        >
          <b-spinner
            v-if="isBusy"
            small
          />
          <v-icon
            v-else
            name="search"
          />
          Buscar paciente
        </b-button>
      </b-col>
    </b-row>
    <register-patient
      v-if="isVisibled"
      :patient="patient"
      :document-number="documentNumber"
      :document-type-id="documentTypeId"
      @registerBed="registerBed"
    />
  </div>
</template>

<script>
import {
	BButton,
	BFormGroup,
	BFormInput,
	BSpinner,
	BRow,
	BCol,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from 'vuelidate/lib/validators'
import RegisterPatient from './RegisterPatient'

export default {
	inject: ['patientsRepository', 'datafieldsRepository'],
	components: {
		BButton,
		BFormInput,
		BFormGroup,
		BSpinner,
		BRow,
		BCol,
		vSelect,
    RegisterPatient,
	},
  props: {
    location: {
      type: Object,
      default: null,
    },
  },
	data() {
		return {
			document_type_id: null,
			document_number: null,
      documentNumber: null,
      documentTypeId: null,
			documentTypes: [],
			isBusy: false,
      patient: null,
      isVisibled: false,
		}
	},
	async mounted() {
		const me = this
		const documentTypes = await me.datafieldsRepository.getAll('document_type')
		me.documentTypes = documentTypes.data.map(item => ({
			value: item.id,
			text: item.longName,
		}))
	},
	validations: {
		document_type_id: {
			required,
		},
		document_number: {
			required,
		},
	},
	methods: {
		async handleSearchPatient() {
			const me = this
			try {
				me.isBusy = true
        me.isVisibled = false
        me.patient = null
				const { data } = await me.patientsRepository.findPatientByNumberDocument(me.document_number)

				if (data.data.length > 0) {
          data.data.forEach(element => {
            me.patient = element
          })
				} else {
          me.documentNumber = me.document_number
          me.documentTypeId = me.document_type_id
					me.patient = null
				}
			} catch (error) {
				// console.log(error)
			} finally {
				me.isBusy = false
        me.isVisibled = true
        me.document_type_id = null
        me.document_number = null
			}
		},
    registerBed(id) {
      const me = this
      me.$emit('registerBed', id)
    },
	},
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
