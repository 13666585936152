<template>
  <b-col md="12">
    <b-card
      no-body
      class="card-company-table"
    >
      <b-table
        :items="patients"
        responsive
        :fields="fields"
        class="mt-1"
        small
      >
        <!-- name -->
        <template #cell(name)="data">
          <div class="d-flex align-items-center">
            <div>
              <div class="font-weight-bolder">
                {{ data.item.name }}
              </div>
              <b-badge variant="dark">
                {{ data.item.device ? data.item.device : '-' }}°C
              </b-badge>
            </div>
          </div>
        </template>

        <!-- risk -->
        <template #cell(risk)="data">
          <div
            v-if="data.item.location_patient"
            class="d-flex align-items-center"
          >
            <span v-if="isRisk(data.item.location_patient.risks)">{{ data.item.location_patient.risks[0].name }}</span>
            <!-- v-if="data.item.risk == 'Alergia'" -->
            <b-button
              v-if="isRisk(data.item.location_patient.risks) && data.item.location_patient.risks[0].typeInput == 'time'"
              v-b-tooltip.hover.top="'Tiempo ulcera'"
              size="sm"
              variant="secondary"
              class="btn-icon mr-25"
            >
              <feather-icon icon="ClockIcon" />
            </b-button>
            <b-button
              :id="data.item.location_patient.id"
              size="sm"
              variant="secondary"
              class="btn-icon"
            >
              <feather-icon icon="ListIcon" />
            </b-button>
            <b-tooltip
              :target="data.item.location_patient.id"
              triggers="hover"
            >
              <ul>
                <li
                  v-for="(
                    risk, index
                  ) in data.item.location_patient.risks.slice(0, 3)"
                  :key="index"
                >
                  {{ risk.name }}
                </li>
              </ul>
            </b-tooltip>
          </div>
        </template>

        <!-- patient -->
        <template #cell(patient)="data">
          <div
            v-if="data.item.patient"
            class="d-flex flex-column"
          >
            <span class="font-weight-bolder mb-25">
              {{
                data.item.patient.first_name + ' ' + data.item.patient.last_name
              }}
            </span>
          </div>
        </template>

        <template #cell(exams)="data">
          <div
            v-if="data.item.patient"
            class=""
            style="cursor: pointer;"
            @click="modalExamen(data.item)"
          >
            <div class="text-center">
              <b-progress
                :max="100"
                animated
                variant="info"
              >
                <b-progress-bar :value="((data.item.exams.length / data.item.examsTotal.length) * 100)">
                  <strong>{{ ((data.item.exams.length / data.item.examsTotal.length) * 100) }}</strong>
                </b-progress-bar>
              </b-progress>
              <span
                class=""
              ><strong>{{ data.item.exams.length }} / {{ data.item.examsTotal.length }}</strong></span>
            </div>
          </div>
        </template>

        <!-- <template #cell(medicines)="data">
          <div
            v-if="data.item.patient"
            class="d-flex align-items-center"
            @click.prevent="asignarMedicamentos(data.item)"
          >
            <div class="container-indicative-progress">
              <div
                style="width: 66%"
                class="container-progress"
              >
                <span class="value-progress">66%</span>
                <div class="progresss" />
              </div>
              <span class="indicative">2/3</span>
            </div>
          </div>
        </template> -->

        <template #cell(diet)="data">
          <div
            v-if="data.item.patient"
            class="d-flex align-items-center min"
          >
            <div
              v-if="isBusy"
              class="text-center text-primary"
            >
              <b-spinner class="align-middle" />
            </div>
            <div
              v-else-if="isError"
              class="btn_reload"
              @click.prevent="$emit('reloadDiets')"
            >
              <b-icon-arrow-clockwise />
            </div>
            <div
              v-else-if="isEmpty"
              style="cursor: pointer;"
              @click.prevent="asignarDiet()"
            >
              <b-badge variant="danger">
                Asignar
              </b-badge>
            </div>
            <div
              v-else
              style="cursor: pointer;"
              @click.prevent="asignarDiet()"
            >
              <div
                v-if="data.item.orders.length > 0"
                class="d-flex"
              >
                <div
                  v-for="(option, index) in data.item.orders"
                  :key="index"
                  v-b-tooltip.hover.top="textDietTooltip(option.type)"
                  class="box_food"
                  :class="colorDiet(option.type, option.status)"
                >
                  <div>
                    {{ textDiet(option.type) }}
                  </div>
                </div>
              </div>
              <div v-else>
                <b-badge variant="danger">
                  Asignar
                </b-badge>
              </div>
            </div>
          </div>
        </template>

        <!-- actions -->
        <template #cell(actions)="data">
          <!-- v-b-tooltip.hover.top="'Ingresar paciente'" -->
          <b-button
            v-if="isMaintenance1(data.item)"
            v-b-tooltip.hover.left="'Ingresar paciente'"
            size="sm"
            variant="primary"
            class="btn-icon mr-25"
            @click="goFindPatient(data.item)"
          >
            <feather-icon icon="PlusCircleIcon" />
          </b-button>
          <b-button
            v-else-if="isMaintenance2(data.item)"
            :id="data.item.id"
            size="sm"
            class="btn-icon btn__more"
          >
            <feather-icon icon="MoreVerticalIcon" />
          </b-button>
          <b-button
            v-else
            v-b-tooltip.hover.left="'Fuera de servicio'"
            size="sm"
            variant="danger"
            class="btn-icon mr-25"
            @click.prevent="modalMaintenance(data.item)"
          >
            <feather-icon icon="AlertCircleIcon" />
          </b-button>
          <b-popover
            :target="data.item.id"
            triggers="hover"
            placement="left"
          >
            <div class="d-flex flex-column">
              <!-- v-b-tooltip.hover.top="'Dar de alta'" -->
              <b-button
                v-if="data.item.patient"
                v-b-tooltip.hover.left="'Dar de alta'"
                size="sm"
                variant="warning"
                class="btn-icon mb-25"
                @click="altaInfo(data.item)"
              >
                <feather-icon icon="LogOutIcon" />
              </b-button>
              <!-- v-b-tooltip.hover.top="'Historial paciente'" -->
              <b-button
                v-if="data.item.patient"
                v-b-tooltip.hover.left="'Historial paciente'"
                size="sm"
                variant="secondary"
                class="btn-icon mb-25"
                @click="redirect('patientHistory', data.item.patient.id)"
              >
                <feather-icon icon="EyeIcon" />
              </b-button>
              <!-- v-b-tooltip.hover.top="'Trasladar paciente'" -->
              <b-button
                v-if="data.item.patient"
                v-b-tooltip.hover.left="'Trasladar paciente'"
                size="sm"
                variant="info"
                class="btn-icon mb-25"
                @click="transferPatient(data.item)"
              >
                <feather-icon icon="RepeatIcon" />
              </b-button>
              <!-- v-b-tooltip.hover.top="'Falla atención'" -->
              <b-button
                v-if="data.item.patient"
                v-b-tooltip.hover.left="'Falla atención'"
                v-b-modal.modal-failure-attention
                size="sm"
                variant="success mb-25"
                class="btn-icon"
              >
                <feather-icon icon="AlertTriangleIcon" />
              </b-button>
            </div>
          </b-popover>
        </template>
      </b-table>
    </b-card>
  </b-col>
</template>

<script>
import {
	BCard,
	BTable,
	BButton,
	VBModal,
	VBTooltip,
	BBadge,
	BCol,
	BTooltip,
	BPopover,
  BSpinner,
  BIconArrowClockwise,
  BProgress,
  BProgressBar,
} from 'bootstrap-vue'

export default {
	components: {
		BCard,
		BTable,
		BButton,
		BBadge,
		BCol,
		BTooltip,
		BPopover,
    BSpinner,
    BIconArrowClockwise,
    BProgress,
    BProgressBar,
	},
	directives: {
		'b-modal': VBModal,
		'b-tooltip': VBTooltip,
	},
	props: {
		patients: {
			type: Array,
			default: () => [],
		},
    isBusy: {
      type: Boolean,
      default: false,
    },
    isError: {
      type: Boolean,
      default: false,
    },
    isEmpty: {
      type: Boolean,
      default: false,
    },
	},
	data() {
		return {
			fields: [
				{ key: 'name', label: 'CAMA' },
				{ key: 'risk', label: 'RIESGO' },
				{ key: 'patient', label: 'PACIENTE' },
				{ key: 'eps.long_name', label: 'EPS' },
				{ key: 'exams', label: 'EXÁMANES' },
				// { key: 'medicines', label: 'MEDICAMENTOS' },
				{ key: 'diet', label: 'DIETAS' },
				{ key: 'actions', label: 'ACCIONES' },
			],
		}
	},
	methods: {
		isRisk(arr) {
			console.log(arr)
			return arr.length > 0
		},
		goFindPatient(item) {
      this.$emit('modalFindPatient', item)
			// this.$router.push({ name: 'find-paciente', params: { locationId: id } })
		},
		asignarMedicamentos(item) {
			// this.$router.push({ name: 'assignMedicines' })
      this.$emit('assignMedicines', item)
		},
		asignarDiet() {
			this.$router.push({ name: 'nurse' })
		},
		modalExamen(item) {
      this.$emit('modalExamen', item)
		},
		transferPatient(item) {
			this.$emit('transferPatient', item.location_patient.id, item.area_id)
		},
		altaInfo(item) {
			this.$bvModal
				.msgBoxConfirm(
					'¿Está seguro que desea dar de alta informativo a este paciente?, esta acción informará al personal encargado del proceso de salida del paciente.',
					{
						title: 'De alta informativo',
						size: 'sm',
						okVariant: 'warning',
						okTitle: 'Alta info',
						buttonSize: 'sm',
						hideHeaderClose: false,
						cancelTitle: 'Cancelar',
						cancelVariant: 'outline-secondary',
						centered: true,
						modalClass: 'modal-warning',
					},
				)
				.then(async value => {
					if (value) {
						this.$emit('altaInfo', item.location_patient.id, item.area_id)
					}
				})
		},
    redirect(name, id) {
			console.log(name, id)
      this.$router.push({ name: name, params: { id: id } })
    },
    textDiet(type) {
      let text = ''

      if (type == '1') {
        text = 'D'
      } else if (type == '2') {
        text = 'A'
      } else {
        text = 'C'
      }
      return text
    },
    colorDiet(type, status) {
      let color = ''

      if (type == '1' && status) {
        color = 'breakfast'
      } else if (type == '2' && status) {
        color = 'luch'
      } else if (type == '3' && status) {
        color = 'dinner'
      }
      return color
    },
    textDietTooltip(type) {
      let text = ''

      if (type == '1') {
        text = 'Desayuno'
      } else if (type == '2') {
        text = 'Almuerzo'
      } else {
        text = 'Cena'
      }
      return text
    },
    isMaintenance1(item) {
      let isVisible = false

      if (item.patient) {
        isVisible = false
      } else if (item.bed_request && item.bed_request.length > 0) {
        const bedActive = item.bed_request.find(el => el.finished_date == null)
        if (bedActive) isVisible = false
      } else if (item.maintenance_service && item.maintenance_service.length > 0) {
        const maintenanceActive = item.maintenance_service.find(el => el.status == '1')
        if (maintenanceActive) isVisible = false
      } else {
        isVisible = true
      }

      return isVisible
    },
    isMaintenance2(item) {
      let isVisible = false

      if (item.patient) {
        isVisible = true
      } else if (item.bed_request && item.bed_request.length > 0) {
        const bedActive = item.bed_request.find(el => el.finished_date == null)
        if (bedActive) isVisible = false
      } else if (item.maintenance_service && item.maintenance_service.length > 0) {
        const maintenanceActive = item.maintenance_service.find(el => el.state == '1')
        if (maintenanceActive) isVisible = false
      }

      return isVisible
    },
    modalMaintenance(item) {
      const me = this
      let value = null
      if (item.bed_request && item.bed_request.length > 0) {
        value = item.bed_request.find(el => el.finished_date == null)
      } else {
        value = item.maintenance_service.find(el => el.state == '1')
      }

      me.$emit('modalMaintenance', { observations: value.observations })
    },
	},
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
	.dark-layout & {
		background: $theme-dark-body-bg !important;
	}
}

.margin-rigth {
	margin-right: 0.5rem;
}

.container-indicative-progress {
	display: flex;
	flex-direction: column;
	flex: 1;
	align-items: center;
	cursor: pointer;
}
.container-progress {
	height: 1rem;
	width: 100%;
	background-color: #f5f5f5;
	position: relative;
	overflow: hidden;
	border-radius: 0.4rem;
	.value-progress {
		position: absolute;
		text-align: center;
		right: 0rem;
		left: 0rem;
		font-size: 0.7rem;
		top: 0rem;
		font-weight: bold;
	}
	.progresss {
		background-color: #30cfe8;
		height: 100%;
		width: 33%;
	}
}
.dieta {
	background-color: #7367f0;
}
.indicative {
	margin-top: 0.2rem;
}

.btn__more {
  background-color: #453b7a !important;
  border: 1px #453b7a !important;
}

.btn_reload {
  color: #453b7a !important;
  cursor: pointer;
  font-size: 25px;
}

.box_food {
  background: #b2b2b2;
  width: 100%;
  height: 30px;
  margin-right: 5px;
  font-size: 16px;
  color: black;
  border-radius: 0.2rem;
  text-align: center;
  display: flex;
  align-items: center;
  padding: 8px;
  font-weight: 700;
}

.box_food.breakfast {
  background-color: #28c76f;
  color: white;
}

.box_food.luch {
  background-color: #28c76f;
  color: white;
}

.box_food.dinner {
  background-color: #28c76f;
  color: white;
}
</style>
