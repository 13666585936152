<template>
  <div>
    <b-row>
      <b-col
        lg="12"
        md="12"
        sm="12"
        class="text-center mb-2"
      >
        <h4>Datos del paciente</h4>
      </b-col>
      <b-col
        lg="6"
        md="6"
        sm="12"
      >
        <b-form-group
          label="Tipo de documento"
          label-for="document_type_id"
        >
          <v-select
            v-model="formData.document_type_id"
            label="text"
            placeholder="Seleccione tipo de documento"
            :options="documentTypes"
            :reduce="option => option.value"
          >
            <div slot="no-options">
              No hay opciones
            </div>
          </v-select>
        </b-form-group>
      </b-col>
      <b-col
        lg="6"
        md="6"
        sm="12"
      >
        <b-form-group
          label="Número de Documento"
          label-for="document_number"
        >
          <b-form-input
            id="document_number"
            v-model="formData.document_number"
            placeholder="Número de Documento"
          />
        </b-form-group>
      </b-col>
      <b-col
        lg="6"
        md="6"
        sm="12"
      >
        <b-form-group
          label="Nombres"
          label-for="first_name"
        >
          <b-form-input
            id="first_name"
            v-model="formData.first_name"
            placeholder="Nombres"
          />
        </b-form-group>
      </b-col>
      <b-col
        lg="6"
        md="6"
        sm="12"
      >
        <b-form-group
          label="Apellidos"
          label-for="last_name"
        >
          <b-form-input
            id="last_name"
            v-model="formData.last_name"
            placeholder="Apellidos"
          />
        </b-form-group>
      </b-col>
      <b-col
        lg="6"
        md="6"
        sm="12"
      >
        <b-form-group
          label="Genero"
          label-for="gender_id"
        >
          <v-select
            v-model="formData.gender_id"
            label="text"
            placeholder="Seleccione género"
            :options="genders"
            :reduce="option => option.value"
          >
            <div slot="no-options">
              No hay opciones
            </div>
          </v-select>
        </b-form-group>
      </b-col>
      <b-col
        lg="6"
        md="6"
        sm="12"
      >
        <date-picker
          v-model="formData.birth_date"
          :attributes="attrs"
          mode="date"
          :model-config="modelConfig"
          :available-dates="{ start: null, end: new Date() }"
        >
          <template v-slot="{ inputValue, inputEvents }">
            <b-form-group
              label="Fecha de nacimiento*"
              label-for="birth_date"
            >
              <b-form-input
                :value="inputValue"
                v-on="inputEvents"
              />
            </b-form-group>
          </template>
        </date-picker>
      </b-col>
      <b-col md="6">
        <b-form-group
          label="EPS"
          label-for="hpc_id"
        >
          <v-select
            v-model="formData.hpc_id"
            label="text"
            placeholder="Seleccione EPS"
            :options="hpcs"
            :reduce="option => option.value"
          >
            <div slot="no-options">
              No hay opciones
            </div>
          </v-select>
        </b-form-group>
      </b-col>
      <b-col
        lg="12"
        md="12"
        sm="12"
        class="text-center mt-1 mb-1"
      >
        <b-button
          type="button"
          variant="primary"
          :disabled="isBusy || $v.$invalid"
          @click="saveData"
        >
          <b-spinner
            v-if="isBusy"
            small
          />
          <v-icon
            v-else
            name="save"
          />
          {{ titleButton }}
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
 BRow, BCol, BFormGroup, BFormInput, BButton, BSpinner,
} from 'bootstrap-vue'
import { required } from 'vuelidate/lib/validators'
import vSelect from 'vue-select'
import { v4 as uuidv4 } from 'uuid'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'

export default {
  inject: ['patientsRepository', 'datafieldsRepository', 'epsRepository'],
  components: {
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    vSelect,
    BButton,
    BSpinner,
    DatePicker,
  },
  props: {
    patient: {
      type: Object,
      default: null,
    },
    documentNumber: {
      type: String,
      default: null,
    },
    documentTypeId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isBusy: false,
      formData: {
        first_name: '',
        last_name: '',
        document_number: '',
        birth_date: '',
        document_type_id: '',
        gender_id: '',
        hpc_id: '',
      },
      documentTypes: [],
      genders: [],
      hpcs: [],
      titleButton: null,
      modelConfig: {
				type: 'string',
				mask: 'YYYY-MM-DD',
			},
			attrs: [
				{
					highlight: 'grenn',
					dates: new Date(),
				},
			],
    }
  },
  validations: {
    formData: {
      first_name: {
        required,
      },
      last_name: {
        required,
      },
      document_number: {
        required,
      },
      birth_date: {
        required,
      },
      document_type_id: {
        required,
      },
      hpc_id: {
        required,
      },
    },
  },
  async created() {
    const me = this

    const documentTypes = await me.datafieldsRepository.getAll('document_type')
		me.documentTypes = documentTypes.data.map(item => ({
			value: item.id,
			text: item.longName,
		}))

    const genders = await me.datafieldsRepository.getAll('gender')
      me.genders = genders.data.map(item => ({
        value: item.id,
        text: item.longName,
      }))

    const { data } = await me.epsRepository.getAll('hpc')
    me.hpcs = data.map(item => ({
      value: item.id,
      text: item.long_name,
    }))

    if (me.patient) {
      me.titleButton = 'Actualizar'
      me.formData = { ...me.formData, ...me.patient }
    } else {
      me.titleButton = 'Registrar'
      me.formData.document_number = me.documentNumber
      me.formData.document_type_id = me.documentTypeId
    }
  },
  methods: {
    async saveData() {
      const me = this
      try {
        me.isBusy = true
        const id = me.formData.id ? me.formData.id : uuidv4()
        const option = me.formData.id ? 'update' : 'save'
        await me.patientsRepository[option](id, me.formData)
        me.$bvModal.hide('modal-find-patient')
        me.$emit('registerBed', id)
      } catch (error) {
        // console.log(error)
      } finally {
        me.isBusy = false
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
