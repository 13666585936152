<template>
  <b-row>
    <b-col md="12">
      <b-form-group
        label="Sede*"
        label-for="name"
      >
        <v-select
          label="name"
          :options="records"
          :reduce="(option) => option.id"
          class="select"
          placeholder="Seleccione sede"
          @input="loadServiceByBrand"
        >
          <div slot="no-options">
            No hay opciones
          </div>
        </v-select>
      </b-form-group>
    </b-col>
    <b-col md="12">
      <b-form-group
        label="Servicio*"
        label-for="name"
      >
        <v-select
          v-model="selectedService"
          label="text"
          :options="services"
          :reduce="(option) => option.id"
          class="select"
          :disabled="isDisabledService"
          placeholder="Seleccione servicio"
          @input="loadAreaByService"
        >
          <div slot="no-options">
            No hay opciones
          </div>
        </v-select>
      </b-form-group>
    </b-col>
    <b-col md="12">
      <b-form-group
        label="Area*"
        label-for="name"
      >
        <v-select
          v-model="selectedArea"
          label="text"
          :options="areas"
          :reduce="(option) => option.id"
          class="select"
          :disabled="isDisabledArea"
          placeholder="Seleccione area"
          @input="loadLocationPatient"
        >
          <div slot="no-options">
            No hay opciones
          </div>
        </v-select>
      </b-form-group>
    </b-col>
    <b-col md="12">
      <b-form-group
        label="Cama*"
        label-for="name"
      >
        <v-select
          v-model="formData.location_id"
          label="text"
          :options="locationPatients"
          :reduce="(option) => option.id"
          class="select"
          :disabled="isDisabledLocation"
          placeholder="Seleccione cama"
        >
          <div slot="no-options">
            No hay opciones
          </div>
        </v-select>
      </b-form-group>
    </b-col>
    <b-col
      md="12"
      class="text-right"
    >
      <b-button
        type="button"
        variant="primary"
        :disabled="isBusy || $v.$invalid"
        @click="saveData"
      >
        <b-spinner
          v-if="isBusy"
          small
        />
        <v-icon
          v-else
          name="save"
        />
        Trasladar
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import {
 BRow, BCol, BFormGroup, BButton, BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { required } from 'vuelidate/lib/validators'
import { v4 as uuidv4 } from 'uuid'
import CRUDMixin from '@/mixins/CRUDMixin'

export default {
	inject: ['patientBedRepository'],
	components: {
		vSelect,
		BRow,
		BCol,
		BFormGroup,
		BButton,
		BSpinner,
	},
	directives: {
		Ripple,
	},
	mixins: [CRUDMixin],
	props: {
		locationPatientId: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			selectedService: null,
			selectedArea: null,
			records: [],
			services: [],
			areas: [],
			locationPatients: [],
			isDisabledService: true,
			isDisabledArea: true,
			isDisabledLocation: true,
			isBusy: false,
			formData: {
				location_id: null,
			},
			repository: 'patientBedRepository',
		}
	},
	validations: {
		formData: {
			location_id: {
				required,
			},
		},
	},
	async mounted() {
		const me = this
		await me.handleLoadData2()
	},
	methods: {
		async loadServiceByBrand(id) {
			const me = this
			try {
				me.resetSelect('1')
				const res = await me.patientBedRepository.getAllServicesByBrand(id)
				const { data } = res
				if (data.length > 0) {
					me.isDisabledService = false
					data.forEach(el => {
						me.services.push({ id: el.id, text: el.name })
					})
				} else {
					me.isDisabledService = true
					me.$bvToast.toast(
						'No existen servicios registrado para la sede seleccionada',
						{
							title: 'Sin servicios',
							toaster: 'b-toaster-bottom-right',
							variant: 'danger',
							solid: true,
							appendToast: true,
						},
					)
				}
			} catch (error) {
				// console.log(error)
			}
		},
		async loadAreaByService(id) {
			const me = this
			try {
				me.resetSelect('2')
				const res = await me.patientBedRepository.getAllAreaByService(id)
				const { data } = res
				data.forEach(el => {
					me.areas.push({ id: el.id, text: el.name })
				})
				me.isDisabledArea = false
			} catch (error) {
				me.isDisabledArea = true
				me.$bvToast.toast(
					'No existen areas registrado para el servicio seleccionado',
					{
						title: 'Sin areas',
						toaster: 'b-toaster-bottom-right',
						variant: 'danger',
						solid: true,
						appendToast: true,
					},
				)
				// console.log(error)
			}
		},
		async loadLocationPatient(id) {
			const me = this
			try {
				me.locationPatients = []
				me.resetSelect('3')
				const res = await me.patientBedRepository.getAllLocationPatient(id)
				res.forEach(el => {
					el.list.forEach(elem => {
						if (
							!elem.location_patient &&
							elem.bed_request &&
							elem.bed_request.length == 0 &&
							elem.maintenance_service &&
							elem.maintenance_service.length == 0
						) {
							me.locationPatients.push({
								id: elem.id,
								text: elem.name,
							})
						}
					})
				})
				if (me.locationPatients.length == 0) {
					me.isDisabledLocation = true
					me.$bvToast.toast(
						'No existen camas desocupadas para el subarea seleccionada',
						{
							title: 'Sin camas',
							toaster: 'b-toaster-bottom-right',
							variant: 'danger',
							solid: true,
							appendToast: true,
						},
					)
				} else {
					me.isDisabledLocation = false
				}
			} catch (error) {
				me.isDisabledLocation = true
				// console.log(error)
				me.$bvToast.toast(
					'No existen camas registradas para el subarea seleccionada',
					{
						title: 'Sin camas',
						toaster: 'b-toaster-bottom-right',
						variant: 'danger',
						solid: true,
						appendToast: true,
					},
				)
			}
		},
		resetSelect(type) {
			const me = this
			if (type == '1') {
				me.services = []
				me.areas = []
				me.locationPatients = []
				me.selectedService = null
				me.selectedArea = null
				me.formData.location_id = null
				me.isDisabledService = true
				me.isDisabledArea = true
				me.isDisabledLocation = true
			} else if (type == '2') {
				me.areas = []
				me.subareas = []
				me.locationPatients = []
				me.selectedArea = null
				me.formData.location_id = null
				me.isDisabledArea = true
				me.isDisabledLocation = true
			} else {
				me.locationPatients = []
				me.formData.location_id = null
				me.isDisabledLocation = true
			}
		},
		async saveData() {
			const me = this
			try {
				me.isBusy = true
				const uuid = uuidv4()
				await me.patientBedRepository.movePatient(
					me.locationPatientId,
					uuid,
					me.formData,
				)
				me.$emit('reloadLocationPatient', me.locationPatientId)
				this.$bvModal.hide('modal-transfer-patiente')
			} catch (error) {
				// console.log(error)
			} finally {
				me.isBusy = false
			}
		},
	},
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
