import writeXlsxFile from 'write-excel-file'

export default {
	methods: {
		async exportPatient(patients) {
			const me = this
			const schema = [
				{
					column: 'Cama',
					type: String,
					value: patient => patient.name,
				},
				{
					column: 'Paciente',
					type: String,
					value: patient => patient.patient.first_name + ' ' + patient.patient.last_name,
				},
				{
					column: 'Tipo de Docuemento',
					type: String,
					value: patient => patient.patient.document_type.long_name,
				},
				{
					column: 'Numero de Documento',
					type: String,
					value: patient => patient.patient.document_number,
				},
				{
					column: 'Genero',
					type: String,
					value: patient => patient.patient.gender.long_name,
				},
				{
					column: 'EPS',
					type: String,
					value: patient => patient.patient.hpc.long_name,
				},
				{
					column: 'Riesgos',
					type: String,
					value: patient => patient.location_patient?.risks.map(item => item.name).join(', ') ?? '',
				},
				{
					column: 'Llamados Totales',
					type: String,
					value: patient => patient.patient.hpc.long_names ?? '0',
				},
				{
					column: 'Llamados Antendidos',
					type: String,
					value: patient => patient.patient.hpc.long_names ?? '0',
				},
				{
					column: 'Llamados No Antendidos',
					type: String,
					value: patient => patient.patient.hpc.long_names ?? '0',
				},
				{
					column: 'Desayunos',
					type: String,
					value: patient => patient.patient.hpc.long_names ?? '0',
				},
			]
			await writeXlsxFile(patients, {
				schema,
				fileName: 'file.xlsx',
			})
		},
	},
}
