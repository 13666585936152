<template>
  <b-modal
    id="modal-failure-attention"
    centered
    title="Registrar falla en la atención"
    size="lg"
  >
    <b-row>
      <b-col
        cols="12"
        class="mb-1"
      >
        <b-form-group
          label="Falla generada por"
        >
          <v-select
            v-model="formData.failure"
            :value="listFailure.label"
            :options="listFailure"
            :reduce="val => val.value"
            :clearable="false"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        class="mb-1"
      >
        <!-- search input -->
        <div class="custom-search d-flex justify-content-end">
          <b-form-group>
            <div class="d-flex align-items-center">
              <label class="mr-1">Buscar</label>
              <b-form-input
                v-model="searchTerm"
                placeholder="Por medicamento"
                type="text"
                class="d-inline-block"
              />
            </div>
          </b-form-group>
        </div>

        <!-- table -->
        <vue-good-table
          :columns="columns"
          :rows="rows"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm }"
          :select-options="{
            enabled: true,
            selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: true, // disable the select info panel on top
            selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
          }"
          :pagination-options="{
            enabled: true,
            perPage:pageLength
          }"
        >
          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap ">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['3','5','10']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-col>
      <b-col
        cols="12"
        class="mb-1"
      >
        <b-form-group
          label="Descripción de la falla"
        >
          <b-form-textarea
            v-model="formData.description"
            placeholder="Escriba la descripción"
            rows="4"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group
          label="Adjuntar evidencia"
        >
          <b-form-file
            v-model="file"
            placeholder="Escoja o arastre su evidencia aquí..."
            drop-placeholder="Arrastre aquí..."
            multiple
          />
        </b-form-group>
      </b-col>
    </b-row>
    <template #modal-footer="{ close }">
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        v-b-modal.modal-seconday
        variant="outline-secondary"
        @click="close()"
      >
        Cerrar
      </b-button>
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        variant="success"
        :disabled="isBussy || $v.$invalid"
        @click="save()"
      >
        <b-spinner
          v-if="isBussy"
          small
        />
        <v-icon
          v-else
          name="save"
        />
        Guardar
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {
	BCardText,
	BFormGroup,
	BModal,
	VBModal,
	BButton,
	BFormTextarea,
	BFormFile,
	BPagination,
	BFormInput,
	BFormSelect,
	BSpinner,
	BRow,
	BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'
import { required } from 'vuelidate/lib/validators'

// Services
import {
	emmiterAddMessagge,
	showTransferPatientModal$,
} from '@core/services/patientServise'

export default {
	components: {
		BRow,
		BCol,
		BFormGroup,
		BModal,
		BFormTextarea,
		BFormFile,
		BPagination,
		BFormInput,
		BFormSelect,
		BSpinner,
		BButton,
		vSelect,
		VueGoodTable,
	},
	directives: {
		'b-modal': VBModal,
		Ripple,
	},
	data() {
		return {
			subscription: null,
			file: null,
			pageLength: 3,
      dir: false,
      columns: [
        {
          label: 'Medicamentos',
          field: 'name',
        },
        {
          label: 'Programado',
          field: 'programmed',
        },
        {
          label: 'Realizado',
          field: 'realized',
        },
        {
          label: 'Personal Asistencial',
          field: 'care_staff',
        },
      ],
      searchTerm: '',
			listFailure: [
				{ label: 'Exámenes', value: 'value1' },
				{ label: 'Medicamentos', value: 'value2' },
				{ label: 'Dietas', value: 'value3' },
			],
			rows: [
				{
					name: 'Ibuprofeno 500mg',
					programmed: '03/oct/2020 08:00 horas',
					realized: '03/oct/2020 09:00 horas',
					care_staff: 'Ana  MArtinez',
				},
				{
					name: 'Ibuprofeno 300mg',
					programmed: '05/oct/2020 06:00 horas',
					realized: '03/oct/2020 08:00 horas',
					care_staff: 'Luis Diaz',
				},
				{
					name: 'Ibuprofeno 600mg',
					programmed: '04/oct/2020 10:00 horas',
					realized: '03/oct/2020 12:00 horas',
					care_staff: 'Cesar Diaz',
				},
			],
			isBussy: false,
			formData: {
				failure: null,
				description: '',
			},
		}
	},
	validations: {
		formData: {
			failure: {
				required,
			},
			description: {
				required,
			},
		},
	},
	created() {
		this.subscription = showTransferPatientModal$
			.asObservable()
			.subscribe(data => {
				if (data) this.items.push(data)
			})
	},
	beforeDestroy() {
		this.subscription.unsubscribe()
	},
	methods: {
		addMessagge() {
			emmiterAddMessagge({
				mensaje: this.messagge,
			})
			this.mensaje = ''
		},
	},
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
.container-header-card {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.container-list-items {
	display: flex;
	flex-direction: column;
	border: 1px solid gainsboro;
	border-radius: 0.4rem;
	padding: 0rem 0rem;
}
.row-header-item {
	display: flex;
	justify-content: space-around;
	align-items: center;
	padding-top: 1rem;
	margin: 0rem 2rem;
}
.name-item-header {
	font-size: 1.1rem;
	font-weight: 600;
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
}
.row-container-item {
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid gainsboro;
	margin: 0.5rem 3rem;
	padding: 0.5rem 0rem;
}
.container-item {
	flex: 1;
	display: flex;
	justify-content: center;
}
.name-item {
	text-align: center;
}
</style>
