<template>
  <b-row>
    <b-col
      cols="6"
      class="mb-1"
    >
      <b-form-group
        label="Medicamento*"
        label-for="medicine_id"
      >
        <v-select
          v-model="formData.medicine_id"
          label="name"
          :options="medicines"
          :reduce="(option) => option.id"
          placeholder="Seleccione medicamento"
        />
      </b-form-group>
    </b-col>
    <b-col cols="6">
      <b-form-group
        label="Dosis*"
        label-for="dosis"
      >
        <div class="d-flex">
          <b-form-radio
            v-model="formData.dosis"
            name="some-radios"
            class="mr-2"
            value="1"
          >
            Única
          </b-form-radio>
          <b-form-radio
            v-model="formData.dose"
            name="some-radios"
            value="2"
          >
            <div class="d-flex">
              <span class="mr-1">Cada</span>
              <span>
                <b-form-input
                  id="smallInput"
                  v-model="formData.each_hour"
                  size="sm"
                  type="number"
                  style="width: 60px;"
                />
              </span>
            </div>
          </b-form-radio>
        </div>
      </b-form-group>
    </b-col>
    <b-col cols="6">
      <b-form-group
        label="Total orden"
        label-for="total"
      >
        <b-form-input
          v-model="formData.total"
          type="number"
        />
      </b-form-group>
    </b-col>
    <b-col cols="6">
      <b-form-group
        label="Período*"
        label-for="period"
      >
        <b-form-input
          v-model="formData.period"
          type="number"
        />
      </b-form-group>
    </b-col>
    <b-col cols="6">
      <b-form-group
        label="Entregados*"
        label-for="delivered"
      >
        <b-form-input
          v-model="formData.delivered"
          type="number"
        />
      </b-form-group>
    </b-col>
    <b-col cols="6">
      <b-form-group
        label="Pendientes"
        label-for="programmed_time"
      >
        <b-form-input
          v-model="formData.slopes"
          type="number"
        />
      </b-form-group>
    </b-col>
    <b-col cols="6">
      <date-picker
        v-model="formData.scheduled_date"
        :attributes="attrs"
        mode="date"
        :model-config="modelConfig"
        :available-dates="{ start: new Date(), end: null }"
      >
        <template v-slot="{ inputValue, inputEvents }">
          <b-form-group
            label="Fecha*"
            label-for="date"
          >
            <b-form-input
              :value="inputValue"
              v-on="inputEvents"
            />
          </b-form-group>
        </template>
      </date-picker>
    </b-col>
    <b-col
      cols="6"
      class="mb-1"
    >
      <b-form-group
        label="Hora*"
        label-for="time"
      >
        <b-form-input
          v-model="formData.time"
          label="time"
          type="time"
          step="2"
        />
      </b-form-group>
    </b-col>
    <b-col
      cols="12"
      class="text-right"
    >
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        v-b-modal.modal-seconday
        variant="success"
        @click="saveData()"
      >
        Guardar
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import {
  BButton,
  BRow,
  BCol,
  BFormRadio,
  BFormInput,
  BFormGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import { required } from 'vuelidate/lib/validators'
import { v4 as uuidv4 } from 'uuid'
import { mapGetters } from 'vuex'

export default {
  inject: ['medicinesRepository', 'assignMedicinePatientRepository'],
  components: {
    BButton,
    BRow,
    BCol,
    vSelect,
    BFormRadio,
    BFormInput,
    BFormGroup,
    DatePicker,
  },
  directives: {
    Ripple,
  },
  props: {
		patient: {
			type: Object,
			default: null,
		},
	},
  data() {
    return {
      medicines: [],
      formData: {
        period: null,
        dosis: null,
        each_hour: null,
        total: null,
        scheduled_date: null,
        medicine_id: null,
        delivered: null,
        time: null,
      },
      modelConfig: {
				type: 'string',
				mask: 'YYYY-MM-DD',
			},
			attrs: [
				{
					highlight: 'blue',
					dates: new Date(),
				},
			],
    }
  },
  computed: {
    ...mapGetters('session', ['getLoggedUser']),
  },
  mounted() {
    const me = this
    me.loadMedicines()
  },
  methods: {
    async loadMedicines() {
			const me = this
			try {
				const { data } = await me.medicinesRepository.getAll()
				data.forEach(el => {
					me.medicines.push({
						id: el.id,
						name: el.commercial_name + ' ' + el.concentration + ' ' + el.laboratory,
					})
				})
			} catch (error) {
				// console.log(error)
			}
		},
		async saveData() {
			const me = this
			try {
				me.isBusy = true
				const id = me.formData.id ? me.formData.id : uuidv4()
        const option = me.formData.id ? 'update' : 'save'

				await me.assignMedicinePatientRepository[option](id, {
					...me.formData,
					patient_location_id: me.patient.patient.id,
          customer_id: me.getLoggedUser.customer_id,
				})
				me.$emit('save')
			} catch (error) {
				// console.log(error)
			} finally {
				me.isBusy = false
			}
		},
  },
}
</script>

<style lang="scss"></style>
