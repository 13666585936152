<template>
  <b-carousel
    id="carousel-interval"
    controls
    indicators
    :interval="5000"
  >
    <b-carousel-slide
      v-for="(item, index) in patientsAltaInfo"
      :key="index"
    >
      <template #img>
        <b-card class="card__carousel p-1">
          <b-row>
            <b-col
              cols="12"
              class="mb-1"
            >
              <b-table
                small
                :fields="item.fields"
                :items="item.items"
                class="table_background"
              >
                <template #cell(process)="">
                  <div
                    class="d-flex align-items-center min"
                  >
                    <div class="container-indicative-progress">
                      <div
                        style="width: 50%"
                        class="container-progress"
                      >
                        <span class="value-progress">50%</span>
                        <div class="progresss dieta" />
                      </div>
                      <span class="indicative">2/4</span>
                    </div>
                  </div>
                </template>
              </b-table>
            </b-col>
            <b-col
              cols="12"
              class="text-center"
            >
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                v-b-modal.modal-seconday
                variant="success"
                class="mr-2"
                @click="altaFisico(item.locationPatientId, item.areaId)"
              >
                Alta físico
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                v-b-modal.modal-seconday
                variant="outline-primary"
                @click="altaCancel(item.locationPatientId, item.areaId)"
              >
                Cancelar
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </template>
    </b-carousel-slide>
  </b-carousel>
</template>

<script>
import {
 BCarousel,
 BCarouselSlide,
 BTable,
 BCard,
 BButton,
 BCol,
 BRow,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCarousel,
    BCarouselSlide,
    BTable,
    BCard,
    BButton,
    BCol,
    BRow,
  },
  directives: {
    Ripple,
  },
  props: {
    patientsAltaInfo: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {}
  },
  methods: {
    altaFisico(locationPatientId, areaId) {
      this.$bvModal
				.msgBoxConfirm('¿Está seguro que desea dar de alta físico a este paciente?', {
					title: 'Dar de alta físico',
					size: 'sm',
					okVariant: 'success',
					okTitle: 'Alta físico',
					buttonSize: 'sm',
					hideHeaderClose: false,
					cancelTitle: 'Cancelar',
					cancelVariant: 'outline-secondary',
					centered: true,
					modalClass: 'modal-success',
				})
				.then(async value => {
					if (value) {
            this.$emit('altaFisico', locationPatientId, areaId)
					}
				})
    },
    altaCancel(locationPatientId, areaId) {
      this.$bvModal
				.msgBoxConfirm('¿Está seguro que desea cancelar de alta info a este paciente?', {
					title: 'Cancelar de alta info',
					size: 'sm',
					okVariant: 'danger',
					okTitle: 'Si',
					buttonSize: 'sm',
					hideHeaderClose: false,
					cancelTitle: 'No',
					cancelVariant: 'outline-secondary',
					centered: true,
					modalClass: 'modal-danger',
				})
				.then(async value => {
					if (value) {
            this.$emit('altaCancel', locationPatientId, areaId)
					}
				})
    },
  },
}
</script>
<style lang="css">
  @import './carousel.css';
</style>
