<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        class="mb-1"
      >
        <b-form-group
          label="Area"
        >
          <v-select
            v-model="areaId"
            label="name"
            placeholder="Seleccione area"
            :options="areas"
            :reduce="option => option.id"
            @input="selectSubareas"
          >
            <div slot="no-options">
              No hay opciones
            </div>
          </v-select>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        class="mb-1"
      >
        <b-form-group
          label="Subarea"
        >
          <v-select
            v-model="formData.subarea_id"
            label="name"
            placeholder="Seleccione subarea"
            :options="subareas"
            :reduce="option => option.id"
          >
            <div slot="no-options">
              No hay opciones
            </div>
          </v-select>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        class="mb-1"
      >
        <b-form-group
          label="Tipo de solicitud"
        >
          <v-select
            v-model="formData.maintenance_type_id"
            :options="typeServices"
            :reduce="option => option.id"
            label="name"
            placeholder="Seleccione tipo de solicitud"
          >
            <div slot="no-options">
              No hay opciones
            </div>
          </v-select>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        class="mb-2"
      >
        <b-form-group
          label="Describa el servicio que requiere*"
        >
          <b-form-textarea
            v-model="formData.observations"
            placeholder="Observaciones"
            rows="3"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        class="text-right"
      >
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="success"
          :disabled="isBusy || $v.$invalid"
          @click="save()"
        >
          <b-spinner
            v-if="isBusy"
            small
          />
          <v-icon
            v-else
            name="save"
          />
          Solicitar
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BButton,
  BRow,
	BCol,
  BFormTextarea,
  BFormGroup,
  BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { required } from 'vuelidate/lib/validators'
import { v4 as uuidv4 } from 'uuid'

export default {
  inject: ['patientBedRepository'],
  components: {
    BButton,
    BRow,
    BCol,
    BFormTextarea,
    BFormGroup,
    BSpinner,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    dataServAreaSub: {
      type: Object,
      default: null,
    },
  },
  data() {
    const me = this
    return {
      isBusy: false,
      areas: [],
      subareas: [],
      typeServices: [],
      formData: {
        subarea_id: null,
        maintenance_type_id: null,
        observations: '',
      },
      areaId: null,
      serviceId: me.dataServAreaSub.serviceId,
    }
  },
  validations: {
    formData: {
      subarea_id: {
        required,
      },
      maintenance_type_id: {
        required,
      },
      observations: {
        required,
      },
    },
  },
  async mounted() {
    const me = this
    await me.loadTypeServices()
    await me.loadAreas()
    await me.loadSubareas()
  },
  methods: {
    async save() {
      const me = this
      try {
        me.isBusy = true
        const uuid = uuidv4()
        const res = await me.patientBedRepository.saveMaintenanceService(uuid, me.formData)
        me.$bvToast.toast('Registro guardado con éxito', {
					title: 'Éxito',
					toaster: 'b-toaster-bottom-right',
					variant: 'success',
					solid: true,
					appendToast: true,
				})
        this.$bvModal.hide('modal-room-service')
      } catch (error) {
        // console.log(error)
        me.$bvToast.toast('Error', {
					title: 'Ocurrió un error al guardar el registro',
					toaster: 'b-toaster-bottom-right',
					variant: 'danger',
					solid: true,
					appendToast: true,
				})
      } finally {
        me.isBusy = false
      }
    },
    async loadTypeServices() {
      const me = this
      try {
        const res = await me.patientBedRepository.getAllTypeService()
        const { data } = res.data
        me.typeServices = data
      } catch (error) {
        // console.log(error)
      }
    },
    async loadAreas() {
      const me = this
      try {
        const res = await me.patientBedRepository.getAllAreaByService(me.serviceId)
        const { data } = res
        data.forEach(el => {
          me.areas.push({ id: el.id, name: el.name })
        })
        me.areaId = me.dataServAreaSub.areaId
      } catch (error) {
        // console.log(error)
      }
    },
    async loadSubareas() {
      const me = this
      me.subareas = []
      try {
        const res = await me.patientBedRepository.getAllSubareaByArea(me.areaId)
        const { data } = res

        data.sort((a, b) => {
          if (a.name > b.name) {
            return 1
          }
          if (a.name < b.name) {
            return -1
          }
          return 0
        })

        data.forEach(el => {
          me.subareas.push({ id: el.id, name: el.name })
        })
      } catch (error) {
        me.subareas = []
        me.$bvToast.toast('No existen subareas registrado para el area seleccionada', {
          title: 'Sin subareas',
          toaster: 'b-toaster-bottom-right',
          variant: 'danger',
          solid: true,
          appendToast: true,
        })
        // console.log(error)
      }
    },
    selectSubareas() {
      const me = this
      me.formData.subarea_id = null
      me.loadSubareas()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
